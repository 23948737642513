<template>
    <div class="invite">
        <Top/>
        <Menu/>
        <div class="invite_content">
            <div class="invite_content01">
                <div class="invite_content01_01">Invite friends and earn more GC</div>
                <div class="invite_content01_02">BONUSES</div>
                <div class="invite_content01_03">
                    <div>Invite a friend</div>
                    <div>+1000 GC for you and your friend</div>
                </div>
                <div class="invite_content01_04">Invite a friend with Telegram Premium+2000 GC for you and your friend</div>
                <div class="invite_content01_05" @click="toMoreBonuses()">More bonuses</div>
            </div>

            <div class="invite_content02">
                <div class="invite_content02_01">
                    <div class="invite_content02_011">My Friend:</div>
                    <div class="invite_content02_012">1000</div>
                </div>
                <div class="invite_content02_02">
                    <div class="invite_content02_021">{{ copyText }}</div>
                    <div class="invite_content02_022" @click="copyValue(copyLinkText)">
                        <i class="el-icon-copy-document"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Top from "../../components/framing/Top";
    import Menu from "../../components/framing/Menu";
    import toolUtils from "../../tools/toolUtils";
    import request from "../../tools/request";

    export default {
        name: "Index",
        components: {Top, Menu},
        data() {
            return {
                userId: 0,
                copyText: null,
                copyLinkText: null,
            }
        },
        watch: {},
        created() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }
        },
        mounted() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }

            this.getVerifyUserLogin();
            this.getUserInfoById();
        },
        destroyed() {
        },
        methods: {
            async getVerifyUserLogin() {
                if (sessionStorage.getItem("userId")) {
                    this.userId = sessionStorage.getItem("userId");

                    await request.getVerifyUserLogin(sessionStorage.getItem("userId")).then((res) => {
                        if (!res.data) {
                            this.$router.push("/login");
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                } else {
                    this.$router.push("/login");
                }
            },
            async getUserInfoById() {
                if (this.userId) {
                    await request.getUserInfoById(this.userId).then((res) => {
                        this.copyText = res.data.user_invitation_code;
                        this.shareUrl(res.data.user_invitation_code);
                    }).catch((err) => {
                        this.$message.error(err.message);
                    });
                }
            },
            shareUrl(code) {
                let gameUrl = "https://t.me/mintagametask_bot/mintask";
                let telegramGameUrl = `https://t.me/share/url?url=`;
                let openId = code ? code : "null";
                let query = `?startapp=` + openId;
                if (gameUrl) {
                    telegramGameUrl += encodeURIComponent(gameUrl);
                }
                if (openId) {
                    telegramGameUrl += encodeURIComponent(query);
                }
                this.copyLinkText = telegramGameUrl;
            },
            toMoreBonuses() {
                this.$message.warning("Coming soon!");
                return;
            },
            copyValue(val) {
                if (val) {
                    toolUtils.copyValue(val);
                    this.$message.success('Copy successful!');
                } else {
                    this.$message.error('No content copied!');
                }
            },
        }
    }
</script>

<style lang="less">
    .invite {
        background-color: #131313;
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        .invite_content {
            position: absolute;
            top: 100px;
            bottom: 100px;
            width: 100%;
            margin: 20px 0;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            .invite_content01 {
                color: white;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;

                border-radius: 10px;
                border: #027d93 solid 0.5px;
                width: 400px;
                .invite_content01_01 {
                    height: 80px;
                    line-height: 80px;
                    font-size: 20px;
                }
                .invite_content01_02 {
                    height: 50px;
                    line-height: 50px;
                    font-size: 19px;
                }
                .invite_content01_03 {
                    margin: 10px 0;
                    line-height: 25px;
                }
                .invite_content01_04 {
                    width: 240px;
                    line-height: 25px;
                }
                .invite_content01_05 {
                    height: 80px;
                    line-height: 80px;
                    font-size: 20px;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .invite_content02 {
                color: white;
                border-radius: 10px;
                border: #027d93 solid 0.5px;
                width: 400px;
                margin-top: 35px;
                .invite_content02_01 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-around;
                    .invite_content02_011 {
                        width: 200px;
                        height: 50px;
                        line-height: 50px;
                    }
                    .invite_content02_012 {
                        width: 45px;
                        height: 50px;
                        line-height: 50px;
                    }
                }
                .invite_content02_02 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-evenly;
                    align-items: center;
                    margin-bottom: 20px;
                    .invite_content02_021 {
                        width: 240px;
                        height: 45px;
                        border-radius: 10px;
                        border: #8d8d8d solid 0.5px;
                        text-align: center;
                        line-height: 45px;
                    }
                    .invite_content02_022 {
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        text-align: center;
                        cursor: pointer;
                        border-radius: 10px;
                        border: #8d8d8d solid 0.5px;
                    }
                }
            }
        }
    }
</style>
